import { Source } from "./types"

const universities: Source[] = [
  {
    id: "pl_u_1",
    name: "Politechnika Wrocławska",
    description: "Poland, Politechnika Wrocławska",
    link: "https://www.youtube.com/@politechnika/playlists",
    icon: "politechnikaWroclawska",
  },
  {
    id: "pl_u_2",
    name: "Uniwersytet Śląski",
    description: "Poland, Uniwersytet Śląski",
    link: "https://www.youtube.com/@uniwersytetslaski/playlists",
    icon: "uniwersytetSlaski",
  },
]

const publicOrganizations: Source[] = []

const privateOrganizations: Source[] = []

export const polish = {
  universities,
  publicOrganizations,
  privateOrganizations,
}
