import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"
import { ItemLinkTile } from "../../../molecules/ItemLinkTile"
import MenuIcon from "@mui/icons-material/Menu"

import * as S from "./style"
import { sources } from "./sources"
import { useSourcesIcons } from "./images"

interface Props {
  language: string
  onNavOpenClick: () => void
}

const LearningSourcesPageContent: React.FC<Props> = ({
  language,
  onNavOpenClick,
}) => {
  const sourcesIcons = useSourcesIcons()

  const languageKey = (() => {
    switch (language) {
      case "en":
        return "english"
      case "pl":
        return "polish"
      default:
        return "english"
    }
  })()

  return (
    <S.Main>
      <S.BreadcrumbsSection>
        <S.Wrapper>
          <S.NavOpenButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onNavOpenClick}
          >
            <MenuIcon />
          </S.NavOpenButton>
          <S.Breadcrumbs>
            <S.BreadcrumbsLink to="/learning-sources">
              <FormattedMessage
                defaultMessage="Internet courses > {language} > "
                description="information"
                values={{
                  language:
                    language === "pl" ? (
                      <FormattedMessage
                        defaultMessage="Polski"
                        description="information"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="English"
                        description="information"
                      />
                    ),
                }}
              />
            </S.BreadcrumbsLink>
          </S.Breadcrumbs>
        </S.Wrapper>
      </S.BreadcrumbsSection>
      <S.MainSection>
        <S.Wrapper>
          <S.Header>
            <FormattedMessage
              defaultMessage="YouTube Channels"
              description="information"
            />
          </S.Header>
          <S.Description>
            <FormattedMessage
              defaultMessage="The following YouTube channels contain playlists with courses. With each playlist you can create item to learn in the LearnTray app."
              description="information"
            />
          </S.Description>
          {sources[languageKey].universities.length > 0 && (
            <>
              <S.Subheader>
                <FormattedMessage
                  defaultMessage="Universities' Channels"
                  description="information"
                />
              </S.Subheader>
              <S.List>
                {sources[languageKey].universities.map(source => (
                  <S.ItemList key={source.id}>
                    <ItemLinkTile
                      name={source.name}
                      description={source.description}
                      link={source.link}
                      iconFluid={
                        sourcesIcons[source.icon]?.childImageSharp?.fluid
                      }
                      linkType="external"
                    />
                  </S.ItemList>
                ))}
              </S.List>
            </>
          )}
          {sources[languageKey].publicOrganizations.length > 0 && (
            <>
              <S.Subheader>
                <FormattedMessage
                  defaultMessage="Public Organizations' Channels"
                  description="information"
                />
              </S.Subheader>
              <S.List>
                {sources[languageKey].publicOrganizations.map(source => (
                  <S.ItemList key={source.id}>
                    <ItemLinkTile
                      name={source.name}
                      description={source.description}
                      link={source.link}
                      iconFluid={
                        sourcesIcons[source.icon]?.childImageSharp?.fluid
                      }
                      linkType="external"
                    />
                  </S.ItemList>
                ))}
              </S.List>
            </>
          )}
          {sources[languageKey].privateOrganizations.length > 0 && (
            <>
              <S.Subheader>
                <FormattedMessage
                  defaultMessage="Private Organizations' Channels"
                  description="information"
                />
              </S.Subheader>
              <S.List>
                {sources[languageKey].privateOrganizations.map(source => (
                  <S.ItemList key={source.id}>
                    <ItemLinkTile
                      name={source.name}
                      description={source.description}
                      link={source.link}
                      iconFluid={
                        sourcesIcons[source.icon]?.childImageSharp?.fluid
                      }
                      linkType="external"
                    />
                  </S.ItemList>
                ))}
              </S.List>
            </>
          )}
          <S.SmallDivider />
          <S.Header>
            <FormattedMessage
              defaultMessage="Is something missing?"
              description="information"
            />
          </S.Header>
          <S.Description>
            <FormattedMessage
              defaultMessage="Let us know by sending an email at: {email}"
              description="information"
              values={{
                email: <b>contact@learntray.com</b>,
              }}
            />
          </S.Description>
        </S.Wrapper>
      </S.MainSection>
    </S.Main>
  )
}

export { LearningSourcesPageContent }
