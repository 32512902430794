import { Source } from "./types"

const universities: Source[] = [
  {
    id: "en_u_1",
    name: "MIT OpenCourseWare",
    description: "USA, Massachusetts Institute of Technology",
    link: "https://www.youtube.com/@mitocw/playlists",
    icon: "mit",
  },
  {
    id: "en_u_2",
    name: "Stanford Online",
    description: "USA, Stanford University",
    link: "https://www.youtube.com/@stanfordonline/playlists",
    icon: "stanford",
  },
  {
    id: "en_u_3",
    name: "Yale Courses",
    description: "USA, Yale University",
    link: "https://www.youtube.com/@YaleCourses/playlists",
    icon: "yale",
  },
  {
    id: "en_u_4",
    name: "Harvard University",
    description: "USA, Harvard University",
    link: "https://www.youtube.com/@harvard/playlists",
    icon: "harvard",
  },
  {
    id: "en_u_5",
    name: "Caltech",
    description: "USA, California Institute of Technology",
    link: "https://www.youtube.com/@caltech/playlists",
    icon: "caltech",
  },
  {
    id: "en_u_6",
    name: "Virtual University of Pakistan",
    description: "Pakistan, Virtual University of Pakistan",
    link: "https://www.youtube.com/@thevirtualuniversityofpakistan/playlists",
    icon: "virtualUniversityOfPakistan",
  },
  {
    id: "en_u_7",
    name: "University of Iowa Army ROTC",
    description: "USA, University of Iowa Army ROTC",
    link: "https://www.youtube.com/@universityofiowaarmyrotc2439/playlists",
    icon: "armyRotc",
  },
]

const publicOrganizations: Source[] = [
  {
    id: "en_pu_1",
    name: "40th ID Urban Warfare Center",
    description: "USA, US Army",
    link: "https://www.youtube.com/@40thidurbanwarfarecenter68/playlists",
    icon: "usArmy",
  },
  {
    id: "en_pu_2",
    name: "OEC G&V",
    description:
      "USA, The Operational Environment Center Gaming and Visualizations",
    link: "https://www.youtube.com/@tbocsims/playlists",
    icon: "oecGv",
  },
]

const privateOrganizations: Source[] = [
  {
    id: "en_po_1",
    name: "Y Combinator",
    description: "USA, Y Combinator",
    link: "https://www.youtube.com/@ycombinator/playlists",
    icon: "yCombinator",
  },
  {
    id: "en_po_2",
    name: "Caspian Report",
    description: "Azerbaijan, Caspian Report",
    link: "https://www.youtube.com/@CaspianReport/playlists",
    icon: "caspianReport",
  },
]

export const english = {
  universities,
  publicOrganizations,
  privateOrganizations,
}
