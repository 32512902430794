import { useIntl } from "gatsby-plugin-react-intl"
import React from "react"
import { SEO } from "../components/atoms/SEO"
import { LearningSourcesPage } from "../components/sets/home/LearningSourcesPage"

const Sources: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <SEO
        subtitle={intl.formatMessage({
          defaultMessage: "Learning sources",
          description: "website subtitle",
        })}
      />
      <LearningSourcesPage />
    </>
  )
}

export default Sources
