import { graphql, useStaticQuery } from "gatsby"

export const useSourcesIcons = () => {
  const sourcesIcons = useStaticQuery(graphql`
    query {
      mit: file(relativePath: { eq: "sources-icons/mit.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stanford: file(relativePath: { eq: "sources-icons/stanford.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yale: file(relativePath: { eq: "sources-icons/yale.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      harvard: file(relativePath: { eq: "sources-icons/harvard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      caltech: file(relativePath: { eq: "sources-icons/caltech.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      virtualUniversityOfPakistan: file(
        relativePath: { eq: "sources-icons/virtual-university-of-pakistan.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      usArmy: file(relativePath: { eq: "sources-icons/us-army.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yCombinator: file(
        relativePath: { eq: "sources-icons/y-combinator.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      caspianReport: file(
        relativePath: { eq: "sources-icons/caspian-report.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      politechnikaWroclawska: file(
        relativePath: { eq: "sources-icons/politechnika-wroclawska.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uniwersytetSlaski: file(
        relativePath: { eq: "sources-icons/uniwersytet-slaski.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armyRotc: file(relativePath: { eq: "sources-icons/army-rotc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oecGv: file(relativePath: { eq: "sources-icons/oec-gv.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return sourcesIcons
}
