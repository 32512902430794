import { useQuery } from "@apollo/client"
import { useLocation, useParams } from "@reach/router"
import queryString from "query-string"
import {
  FormattedMessage,
  Link,
  navigate,
  useIntl,
} from "gatsby-plugin-react-intl"
import React, { useContext, useState } from "react"
// import { HomePageContent } from "./HomePageContent"
import { CircularProgress, IconButton } from "@mui/material"

// import * as S from "./style"
import { AuthContext } from "../../../../containers/AuthContext"
import { useIntlPathName } from "../../../../hooks/useIntlPathName"
import { HomeLayout, Tab } from "../HomeLayout"
import { userResourceListQuery } from "../../user/queries"
import { userQuery } from "../../auth/LoginPage/queries"
import { LearningSourcesPageContent } from "./LearningSourcesPageContent"

import * as S from "./style"

const LearningSourcesPage: React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = useIntlPathName()

  const languageQueryString = queryString.parse(location.search).l || "en"
  const language =
    typeof languageQueryString === "string"
      ? languageQueryString
      : !!languageQueryString.length
      ? languageQueryString[0] || "en"
      : "en"

  const [navOpened, setNavOpened] = useState(false)

  const handleTabChange = (tab: Tab) => {
    // const query = queryString.parse(location.search)
    navigate(
      queryString.stringifyUrl({
        url: tab.path,
        // query, // DO NOT PASS ANY QUERY STRING FROM PREVIOUS TAB
      })
    )
  }

  const hanndleLanguageChange = (language: string) => {
    navOpened && setNavOpened(false)
  }

  return (
    <>
      <HomeLayout
        variant={"default"}
        activeTab="learning-sources"
        onTabChange={handleTabChange}
      >
        <S.Layout>
          <S.Nav navOpened={navOpened}>
            <S.NavTitle>
              <FormattedMessage
                defaultMessage="Language"
                description="subtitle"
              />
            </S.NavTitle>
            <S.NavList>
              <S.NavListItem active={language === "en"}>
                <S.NavListItemLink
                  to="/learning-sources"
                  onClick={() => hanndleLanguageChange("en")}
                >
                  English
                </S.NavListItemLink>
              </S.NavListItem>
              <S.NavListItem active={language === "pl"}>
                <S.NavListItemLink
                  to="/learning-sources?l=pl"
                  onClick={() => hanndleLanguageChange("pl")}
                >
                  Polski
                </S.NavListItemLink>
              </S.NavListItem>
            </S.NavList>
          </S.Nav>
          <S.LayoutContent>
            {navOpened && (
              <S.NavOpenedOverlay
                onClick={() => navOpened && setNavOpened(false)}
              />
            )}
            <LearningSourcesPageContent
              language={language}
              onNavOpenClick={() => setNavOpened(!navOpened)}
            />
          </S.LayoutContent>
        </S.Layout>
      </HomeLayout>
    </>
  )
}
export { LearningSourcesPage }
