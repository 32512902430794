import { Link } from "gatsby-plugin-react-intl"
import styled from "styled-components"
import React from "react"
import { IconButton } from "@mui/material"

export const Layout = styled.div`
  display: flex;
  width: 100%;
`

export const LayoutContent = styled.div`
  width: 100%;
`

export const NavOpenButton = styled(IconButton)`
  position: relative;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    display: none;
  }
`

export const NavOpenedOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
`

export const Nav = styled.nav<{ navOpened: boolean }>`
  position: fixed;
  display: ${props => (props.navOpened ? "block" : "none")};
  z-index: 100;

  padding: 130px 0 0 0;
  width: 70vw;
  height: 100%;

  background: #fff;
  /* box-shadow: 0 0 60vw #000a; */
  box-shadow: 0 -20px 20px #0004;

  @media screen and (min-width: 600px) {
    position: relative;
    display: block;
    z-index: unset;

    width: 40vw;
  }
  @media screen and (min-width: 1000px) {
    position: relative;
    display: block;
    z-index: unset;

    width: 20vw;
    max-width: 300px;
  }
`

export const NavTitle = styled.span`
  padding: 0 20px;
  font-size: 0.8rem;
`

export const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
`

export const NavListItem = styled.li<{ active?: boolean }>`
  background: ${props => (props.active ? "#e4e4e4" : "inherit")};
`

export const NavListItemLink = styled(Link)`
  padding: 10px 20px;
  color: inherit;
  text-decoration: none;
  width: 100%;
  display: block;
`

export const Main = styled.main`
  width: 100%;
`

export const BreadcrumbsSection = styled.section`
  margin-top: 100px;
  padding: 24px 24px 12px 24px;
  width: 100%;
  background: ${props => props.theme.palette.primary.light};
  color: #fff;
`

export const MainSection = styled.section`
  padding: 24px;
  width: 100%;

  margin-bottom: 40px;
`

export const Wrapper = styled.div`
  margin: 20px 0 20px 0;

  @media screen and (min-width: 800px) {
    margin: 50px 10% 50px 10%;
  }
  @media screen and (min-width: 1200px) {
    margin: 50px 20% 50px 20%;
  }
`

export const Header = styled.h2`
  text-align: left;
  font-size: 1.2rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.5rem;
    margin: 0 0 30px 0;
  }
`

export const Subheader = styled.h3`
  text-align: left;
  font-size: 1rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.2rem;
    margin: 0 0 30px 0;
  }
`

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
`

export const BreadcrumbsLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: #fff;
`

export const Divider = styled.div`
  width: 100%;
  height: 120px;
`

export const SmallDivider = styled.div`
  width: 100%;
  height: 60px;
`

export const List = styled.div`
  margin: 20px 0 20px 0;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const ItemList = styled.div`
  display: grid;
  gap: 20px;
`

export const Description = styled.p`
  text-align: left;
  font-size: 1rem;
  margin: 0 0 10px 0;

  @media screen and (min-width: 800px) {
    font-size: 1.2rem;
    margin: 0 0 30px 0;
  }
`
